<template>
	<div class="loaded">
		<b-container class="content intro" fluid>
			<b-row>
				<b-col>
					<h1>Login</h1>
					<h4>
						If you have already lodged a report via this platform, you can log in here to add more details, send messages or check on progress. <br><br>
						You will need the Report ID you received when you logged the report and the password you chose.<br><br>
						If you have forgotten your password you can reset it <router-link to="/forgot-password">here</router-link>.<br><br>
						If you don’t have the necessary security details you may <router-link to="/step1">submit another report</router-link>, with additional information.
					</h4>
				</b-col>
			</b-row>
		</b-container>
		<b-container class="content body" fluid>
			<b-row>
				<b-col>
					<b-alert :show="!!error" class="mb-35" variant="warning" v-html="error" />
					<b-form class="mb-4 show-invalid" @submit.prevent="submit()">
						<b-form-group
							label="Report ID"
							label-for="report-id"
							:class="{'is-invalid': isSubmitted && !disclosureId.length}"
						>
							<b-form-input id="report-id" v-model="disclosureId" autofocus></b-form-input>
							<div class="invalid-feedback">You must enter a valid Report ID</div>
						</b-form-group>
						<password-form-group :password.sync="password" label="Password" placeholder="" :show-invalid="isSubmitted" />

						<div class="d-flex align-items-center mb-4 form-controls">
							<router-link class="mr-auto" to="/forgot-password">Forgot Password</router-link>
							<btn-submit label="Login" :saving="saving" save-label="Logging In" />
						</div>
					</b-form>					
					<!-- <p>Don't have any report details? Call 1800 765 234 to find out how you can retrieve your report.</p> -->
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import { PasswordFormGroup, BtnSubmit } from '@/components/ui'
import {hasPassword, hasValidate } from '@/mixins'

export default {
	name: 'Login',
	components: {
		PasswordFormGroup, BtnSubmit,
	},
	mixins: [hasPassword, hasValidate],
	data() {
		return {
			password: '',
			disclosureId: '',
			error: false,
			isSubmitted: false,
			saving: false,
		}
	},
	computed: {
		// valid() {
		// 	return this.password.length > 7 && this.disclosureId 
		// }
	},
	methods: {
		async submit() {
			this.isSubmitted = true
			this.error = false
			const valid = await this.validateAll()
			if (!valid) return
			this.saving = true
			const {password, disclosureId} = this
			// probably send to api here and set below on success
			// this.$store.commit('login/set', {password})
			// this.$store.commit('set', {saved: true, report: {disclosureId}})
			this.$store.dispatch('login/login', {password, disclosureId})
				.then(response => {
					this.saving = false
					this.$store.commit('set', {isRetrieving: true})
					if (response.data.isDisclosureSubmitted) {
						const path = this.$route.query.redirect || '/my-portal'
						this.$router.push(path)
					} else {
						this.$store.commit('set', {org: {...this.$store.state.org, selectedDivision: response.data.division }})
						this.$store.dispatch('getDocs')
						this.$router.push('/step1')
					}
				})
				.catch(e => {
					console.log(e, e.response);
					this.saving = false
					this.error = e.response?.data || 'There was a problem logging in. Please check your details and try again.'
				})
		}
	},
	metaInfo: {
		title: 'Login',
	}
}
</script>